import React from 'react'
import GoogleMapReact from 'google-map-react'

import contactStyles from "../styles/pages/contact.module.css"

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default class Map extends React.Component {

	static defaultProps = {
		center: {lat: 14.631962, lng: 121.044293},
		zoom: 15
    };
    
    renderMarkers(map, maps) {
        let marker = new maps.Marker({
            position: this.props.center,
            map,
            title: 'The IMC Block'
        });
    }
    
	render() {
		return (
            <div className={ contactStyles.map }>
                <GoogleMapReact
                    resetBoundsOnResize
                    defaultCenter={this.props.center}
                    defaultZoom={this.props.zoom}
                    bootstrapURLKeys={{
                        key: 'AIzaSyDKF_v8NlxgFOjSUsQGjPiQEh-PAKwKohk'
                    }}
                    onGoogleApiLoaded={({map, maps}) => this.renderMarkers(map, maps)}
                >
                </GoogleMapReact>
            </div>
		);
	}
}